import { Injectable } from '@angular/core';
import { GoogleTagManagerConfig, GoogleTagManagerUser } from '../model/tracker';

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  /**
   * Local history of previous 10 events.
   */
  history: string[] = [];

  /**
   * Initializes the event tracking with Google Tag Manager.
   *
   * @param config the google tag manager container / environment settings
   */
  start(config: GoogleTagManagerConfig) {
    // initialize the google tag manager data layer
    window.dataLayer = [
      {
        debug: config.debug,
      },
    ];

    // add google tag manager script
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${config.auth}&gtm_preview=${config.preview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${config.containerId}');`;
    document.head.appendChild(script);
  }

  /**
   * Pushes user information to the Google Tag Manager data layer.
   *
   * @param user the data to be pushed
   */
  identify(user: GoogleTagManagerUser) {
    window.dataLayer?.push({
      user_properties: user,
    });
  }

  /**
   * Pushes an event to Google Tag Manager data layer.
   * Use feature and action names with spaces or snake_case.
   *
   * @param feature the name of the feature / page. With spaces or snake_case
   * @param action the action that the user performed on the feature / page. With spaces or snake_case
   * @param event_properties the event properties
   */
  event(feature: string, action: string, properties?: any) {
    // normalize feature and action
    feature = feature.toLowerCase().replace(/ /g, '_');
    action = action.toLowerCase().replace(/ /g, '_');

    // combine feature and action as event name
    const event = `${feature}_${action}`;

    // avoid properties of different events from overlapping
    const event_properties: any = {};
    event_properties[event] = {
      feature,
      action,
      ...properties,
    };

    // push to datalayer, which will merge with existing data
    window.dataLayer?.push({
      event,
      event_properties,
    });

    this.sendToLocalHistory(event, properties);
  }

  /**
   * Stores a tracked event in a local array to be logged when errors happen.
   *
   * @param event the event string
   * @param properties the properties sent with the event
   */
  private sendToLocalHistory(event: string, properties?: any): void {
    let activity = event;

    // If a page view, add the path
    if (event === 'page_view') {
      activity = `${activity} - ${location.pathname}`;
    }

    if (properties) {
      const jsonProperties = JSON.stringify(properties);

      if (jsonProperties !== '{}') {
        activity = `${activity} - ${jsonProperties}`;
      }
    }

    this.history.push(activity);

    while (this.history.length > 10) {
      this.history.shift();
    }
  }
}
