import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MessagesService } from '../messages/messages.service';
import { DialogComponent } from './dialog.component';

/**
 * Service to display messages in dialogs.
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private dialog: MatDialog,
    private messagesService: MessagesService,
    private trackerService: TrackerService,
    private snackBar: MatSnackBar
  ) {}

  /**
   * Open a dialog with the provided error message.
   *
   * @param message the message to display
   * @returns a reference to the dialog
   */
  public showError(message: string): MatDialogRef<DialogComponent> {
    const normalizedMessage = this.messagesService.getMessage(message);
    this.trackerService.event('dialog', 'error', { message: normalizedMessage });
    return this.dialog.open(DialogComponent, {
      data: { message: normalizedMessage, type: 'Error' },
    });
  }

  /**
   * Open a snakbar with the provided success message.
   *
   * @param message the message to display
   * @param duration the time, in milliseconds, the snack bar will show before auto dismissing
   * @param dismissText the text to show in the button to dismiss the snack bar
   * @returns a reference to the snack bar
   */
  public showSuccess(message: string, duration: number = 5000, dismissText: string = 'Close'): MatSnackBarRef<TextOnlySnackBar> {
    const normalizedMessage = this.messagesService.getMessage(message);
    this.trackerService.event('snack_bar', 'success', { message: normalizedMessage });

    return this.snackBar.open(normalizedMessage, dismissText, { duration });
  }

  /**
   * Open a dialog to confirm or cancel an action.
   *
   * @param message the message to show
   * @returns the dialog referece object
   */
  public showConfirm(message: string): MatDialogRef<ConfirmDialogComponent> {
    this.trackerService.event('dialog', 'confirm', { message });
    return this.dialog.open(ConfirmDialogComponent, { data: { message } });
  }
}
